import React from 'react';
import './MapPointMarker.scss';
import PropTypes from 'prop-types';
import { MARKER_ICON_NAMES_MAPPING } from '../../../../../common/components/marker/constants/markerConstants';
import NumberCircle from '../../../../../common/components/marker/components/NumberCircle';

export default function MapPointMarker(props) {
  function getIconComponent() {
    if (!props.isStaticFeaturePoint) {
      const icon = props.impactPointData?.icon || MARKER_ICON_NAMES_MAPPING[props.pinType];
      switch (icon) {
        case 'icon-parcel-shops-new': {
          return (
            <span className={`icon ${icon}`}>
              <span className="path1" />
              <span className="path2" />
            </span>
          );
        }
        case 'icon-DPD': {
          return (
            <span className="icon-DPD">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
              <span
                className="path5"
              />
              <span className="path6" />
              <span className="path7" />
            </span>
          );
        }
        case 'icon-gls': {
          return (
            <span className="icon-gls">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
            </span>
          );
        }
        default:
          return (<i className={`icon ${icon}`} />);
      }
    } else {
      const icon = props.iconType || props.pinType;
      return <img alt={`/mapData/${icon}_logo.svg`} className="background-image" src={`/mapData/${icon}_logo.svg`} key={icon} />;
    }
  }

  function getNumberCircle() {
    if (props.mapPointCollectionData && props.mapPointCollectionData > 0) return (<NumberCircle numberToShow={`${props.mapPointCollectionData}`} />);
    return null;
  }

  function getMarkerClassName() {
    if (!props.isStaticFeaturePoint) {
      let className = 'map-point-marker';
      className = `${className}${props.isDisabled ? ' disabled' : ''}`;
      className = `${className}${props.impactPointData?.impactType ? ` ${props.impactPointData?.impactType}` : ''}`;
      if (props.pinType === 'unwantedLocationPin') {
        className = `${className} unwanted-location`;
      }
      return className;
    }
    return '';
  }

  return (
    <div
      className={getMarkerClassName()}
      onMouseEnter={() => {
        props.setShowOnHoverPopup(true);
      }}
      onMouseLeave={() => {
        props.setShowOnHoverPopup(false);
      }}
      onContextMenu={() => {
        props.openRightClickMenu(true);
      }}
    >
      {!props.isStaticFeaturePoint && getNumberCircle()}
      { (props.isSelected && !props.isStaticFeaturePoint) && <div className="selected-ring" />}
      {getIconComponent()}
    </div>
  );
}

MapPointMarker.propTypes = {
  pinType: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  impactPointData: PropTypes.object,
  mapPointCollectionData: PropTypes.number,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  setShowOnHoverPopup: PropTypes.func,
  openRightClickMenu: PropTypes.func.isRequired,
  isStaticFeaturePoint: PropTypes.bool.isRequired
};

MapPointMarker.defaultProps = {
  iconType: null,
  impactPointData: null,
  mapPointCollectionData: null,
  isSelected: false,
  isDisabled: false,
  setShowOnHoverPopup: () => {}
};
