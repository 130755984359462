import { useEffect, useMemo, useRef, useState } from 'react';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import DistanceTypeUtil, { DISTANCE_VALUE_TYPE_KEYS_ARRAY } from '../../../../common/utils/distanceTypeUtil';
import AuthUtil from '../../../../common/utils/authUtil';
import SortUtil from '../../../../common/utils/sortUtil';

export default function useSelectedLocationsCoverages(
  hexType,
  distanceType,
  allPointsStaticData,
  allPointsCoverageData,
  allPointsIsochroneData,
  distancePinsStaticData,
  distancePinsIsochroneData,
  distancePinsCoverageData,
  hexLayersCountMap,
  deliveryAreaTotalCount
) {
  const [coveragePercentage, setCoveragePercentage] = useState(null);
  const [coverageData, setCoverageData] = useState({});
  const [mapPointCountIconData, setMapPointCountIconData] = useState([]);
  const [selectedMapPointsArray, setSelectedMapPointsArray] = useState([]);

  const { selectedMapPoints } = useSelector((state) => state.regionAnalysisState);
  const layersForUser = useRef(AuthUtil.getRegionAnalysisLayers());

  // Extracts the total number of points that have polygon displayed for them in the map. Also groups them by type to
  // be able to display their counts in the menu.
  // Used to set the value for mapPointCountIconData and selectedMapPointsArray
  useEffect(() => {
    const mapPointTypesCount = {};
    const selectedMapPointsData = [];

    // Includes all the map points that have isochrone polygon displayed for them in the map
    selectedMapPoints.forEach((mapPointId) => {
      const mapPoint = allPointsStaticData[mapPointId];
      if (mapPoint) {
        const mapPointGroupType = mapPoint.iconType || mapPoint.type;
        if (!(mapPointTypesCount[mapPointGroupType])) {
          mapPointTypesCount[mapPointGroupType] = {
            type: mapPointGroupType,
            count: 1
          };
        } else {
          mapPointTypesCount[mapPointGroupType].count += 1;
        }

        selectedMapPointsData.push(mapPoint);
      }
    });

    // Includes all distance pins
    if (!_.isEmpty(distancePinsStaticData)) {
      mapPointTypesCount.distancePin = {
        type: 'distancePin',
        count: Object.keys(distancePinsStaticData).length
      };

      Object.values(distancePinsStaticData).forEach((distancePin) => {
        selectedMapPointsData.push({
          name: 'Pin',
          ...distancePin
        });
      });
    }

    setSelectedMapPointsArray(Object.values(selectedMapPointsData));
    setMapPointCountIconData(Object.values(mapPointTypesCount));
  }, [allPointsStaticData, distancePinsStaticData, selectedMapPoints]);

  useEffect(() => {
    // Calculate the coveredHexagonsPerDistanceLevel object to know which hexagons are covered by the selected map points per distance level.
    // Object is of the form: { inner_distance: Set(), outer_distance: Set() }, where the sets contain hexagon ids
    const coveredHexagonsPerDistanceLevel = {};
    DISTANCE_VALUE_TYPE_KEYS_ARRAY.forEach((distanceLevel) => {
      coveredHexagonsPerDistanceLevel[distanceLevel] = new Set();
    });

    selectedMapPoints.forEach((mapPointId) => {
      const selectedMapPointIsochroneData = allPointsIsochroneData[mapPointId];

      if (selectedMapPointIsochroneData) {
        const selectedMapPointCoveredHexagons = selectedMapPointIsochroneData.hexagonsCovered;
        DISTANCE_VALUE_TYPE_KEYS_ARRAY.forEach((distanceLevel) => {
          selectedMapPointCoveredHexagons[distanceLevel].forEach((hexagon) => {
            coveredHexagonsPerDistanceLevel[distanceLevel].add(hexagon);
          });
        });
      }
    });

    Object.values(distancePinsIsochroneData).forEach((distancePinIsochroneData) => {
      DISTANCE_VALUE_TYPE_KEYS_ARRAY.forEach((distanceLevel) => {
        distancePinIsochroneData.hexagonsCovered[distanceLevel].forEach((hexagon) => {
          coveredHexagonsPerDistanceLevel[distanceLevel].add(hexagon);
        });
      });
    });

    const coveredEntitiesPerDistanceLevel = {};
    layersForUser.current.forEach((layer) => {
      coveredEntitiesPerDistanceLevel[layer] = {};
      DISTANCE_VALUE_TYPE_KEYS_ARRAY.forEach((distanceLevel) => {
        coveredEntitiesPerDistanceLevel[layer][distanceLevel] = 0;
        coveredHexagonsPerDistanceLevel[distanceLevel].forEach((hexId) => {
          if (hexLayersCountMap[hexId]) coveredEntitiesPerDistanceLevel[layer][distanceLevel] += hexLayersCountMap[hexId][layer];
        });
      });
    });

    const newCoveragePercentage = {};
    layersForUser.current.forEach((layer) => {
      if (deliveryAreaTotalCount[layer] === 0) newCoveragePercentage[layer] = '0%';
      else {
        newCoveragePercentage[layer] = `${Math.trunc(
          (coveredEntitiesPerDistanceLevel[layer][DistanceTypeUtil.getLargestDistanceKey()] / deliveryAreaTotalCount[layer]) * 100
        )}%`;
      }
    });

    setCoverageData(coveredEntitiesPerDistanceLevel);
    setCoveragePercentage(newCoveragePercentage);
  }, [distancePinsIsochroneData, hexLayersCountMap, selectedMapPoints, deliveryAreaTotalCount, allPointsIsochroneData]);

  // Get the enriched map point data array, which includes the coverage for each map point
  const enrichedMapPointDataArray = useMemo(() => {
    const unsortedEnrichedMapPoints = Object.values(selectedMapPointsArray).map((mapPoint) => {
      const pointCoverage = allPointsCoverageData[mapPoint.id] || distancePinsCoverageData[mapPoint.id];
      if (!pointCoverage) return null;
      return {
        ...mapPoint,
        coverage: pointCoverage[DistanceTypeUtil.getLargestDistanceKey()][hexType]
      };
    }).filter((enrichedMapPoint) => enrichedMapPoint);

    return SortUtil.sortByNumericField(unsortedEnrichedMapPoints, 'coverage');
  }, [allPointsCoverageData, distancePinsCoverageData, hexType, selectedMapPointsArray]);

  return { coveragePercentage, coverageData, mapPointCountIconData, enrichedMapPointDataArray };
}
