export const MAP_ADDITIONAL_FEATURES_DATA = {
  packageLockers: {
    icon: 'icon-parcel-locker-new',
    label: 'Show package lockers'
  },
  parcelShops: {
    icon: 'icon-storefront',
    label: 'Show parcel shops'
  },
  gasStations: {
    icon: 'icon-gas-station',
    label: 'Show gas stations'
  },
  sbbShops: {
    icon: 'icon-gls',
    label: 'Show shops'
  },
  demoShops: {
    icon: 'icon-DPD',
    label: 'Show shopping centers'
  },
  jokicShops: {
    icon: 'icon-storefront',
    label: 'Show stores'
  },
  isochronePin: {
    icon: 'icon-icon-pin-add',
    label: 'Manage map pins'
  }
};

export const MAP_STATIC_FEATURE_TYPES = {
  gasStations: process.env.REACT_APP_DATA_SOURCE !== 'api' ? ['omv'] : [],
  sbbShops: ['sbbShops'],
  demoShops: ['demoShops']
};

export const MAP_ADDITIONAL_FEATURES_TOGGLES = {
  PACKAGE_LOCKERS: 'packageLockers',
  PARCEL_SHOPS: 'parcelShops',
  GAS_STATION: 'gasStations',
  SBB_SHOPS: 'sbbShops',
  JOKIC_SHOPS: 'jokicShops',
  DEMO_SHOPS: 'demoShops'
};

export const MAP_ADDITIONAL_FEATURES_ORDERED_KEYS = ['packageLockers', 'parcelShops', 'gasStations', 'sbbShops', 'jokicShops', 'demoShops', 'isochronePin'];

export const MAP_ADDITIONAL_FEATURES_SIGNALS = { ISOCHRONE_PIN: 'isochronePin' };

export const MAP_ADDITIONAL_FEATURES_CURRENT_COVERAGE_MENU = {
  PACKAGE_LOCKERS: 'packageLockers',
  PARCEL_SHOPS: 'parcelShops'
};

export const MAP_ADDITIONAL_FEATURES_COVERAGE_MENU = {
  PACKAGE_LOCKERS: 'packageLockers',
  PARCEL_SHOPS: 'parcelShops',
  ISOCHRONE_PIN: 'isochronePin'
};

export const FEATURE_KEY_ICON_MAP = {
  name: 'icon-markunread-mailbox-outlined',
  workingHours: 'icon-alarm',
  address: 'icon-location-on-outlined',
  phone: 'icon-phone',
  time: 'icon-alarm',
  numberOfShipments: 'icon-assignment-turned-in-outlined',
  numberOfPackages: 'icon-package',
  timeRange: 'icon-account-eye',
  isochroneCoveredEntitesCount: 'icon-assignment-turned-in-outlined',
  dwellTime: 'icon-history',
  oohPointDistance: 'icon-person-distance'
};
