import { useSelector } from 'react-redux';
import { INITIAL_VIEW_STATE } from '../constants/mapConstants';
import AuthUtil from './authUtil';

function doesMapHaveLayers(map, layers) {
  let response = true;
  layers.forEach((layer) => {
    response = response && map.getLayer(layer);
  });

  return response;
}

function getInitialViewStateForCompany() {
  const tenantId = AuthUtil.getTenantId();
  return getInitialViewStateForCompanyFn(tenantId);
}

function getInitialViewStateForCompanyFn(tenantId) {
  switch (tenantId) {
    case 'dexpress':
      return {
        lat: 46.0542840711986,
        lng: 14.506525254087345,
        zoom: 6.5,
        pitch: 0,
        bearing: 0
      };
    case 'xexpress':
      return {
        lat: 44.0462,
        lng: 17.8751,
        zoom: 7,
        pitch: 0,
        bearing: 0
      };
    case 'eexpress':
      return {
        lat: 44.0462,
        lng: 17.8751,
        zoom: 7,
        pitch: 0,
        bearing: 0
      };
    default:
      return INITIAL_VIEW_STATE;
  }
}

function useInitialViewStateForCompany() {
  const tenantId = useSelector((state) => state.authState?.userPermissions?.userTenant);
  return getInitialViewStateForCompanyFn(tenantId);
}

const MapUtil = {
  doesMapHaveLayers: doesMapHaveLayers,
  getInitialViewStateForCompany: getInitialViewStateForCompany,
  useInitialViewStateForCompany: useInitialViewStateForCompany
};

export default MapUtil;
