import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../pages/RoutePreviewPage.scss';
import './PlanPreview.scss';
import mapboxgl from 'mapbox-gl';
import { useTranslation } from 'react-i18next';
import MapBoxWrapper from '../../../../common/components/wrappers/mapBoxWrapper/MapBoxWrapper';
import { MAP_STYLE } from '../../../../common/constants/mapConstants';
import PinPlottingUtilClass from '../../../../common/utils/pinPlotingUtil';
import ShipmentsUtil from '../../../../common/utils/shipmentsUtil';
import PlanningMapUtil from '../../utils/planningMapUtil';
import PlanningFooter from './PlanningFooter';
import MapUtil from '../../../../common/utils/mapUtil';

/**
 * Show regions, routes and shipments on map
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Plan preview
 * @component
 * @alias PlanPreview
 * @category planning
 */
export default function PlanPreview(props) {
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const sources = useRef([]);
  const layers = useRef([]);
  const markers = useRef([]);
  const PinPlottingUtil = useRef(null);
  const defaultView = MapUtil.useInitialViewStateForCompany();

  /**
   * Draws all shipment markers in the map
   *
   * @function
   */
  const plotPoints = useCallback(() => {
    props.shipmentsData.forEach((planShipments) => {
      const shipmentsWithClass = planShipments.map((shipment) => ShipmentsUtil.addClassNameBasedOnDeliveryType(shipment));
      PinPlottingUtil.current.plotPins(shipmentsWithClass, 'plan');
    });
    PinPlottingUtil.current.centerMapToBounds();
  }, [props.shipmentsData]);

  const showFooter = () => {
    if (props.routeData) return props.routeData.length === 1;
    return false;
  };

  useEffect(() => {
    const initializeMap = () => {
      let mapInstance = null;
      mapInstance = new mapboxgl.Map({
        container: mapContainer.current,
        style: MAP_STYLE,
        center: [
          props?.previousViewState?.longitude ? props.previousViewState.longitude : defaultView.lng,
          props?.previousViewState?.latitude ? props.previousViewState.latitude : defaultView.lat
        ],
        zoom: props?.previousViewState?.zoom ? props.previousViewState.zoom : defaultView.zoom
      });

      // mapInstance.addControl(new mapboxgl.FullscreenControl());
      mapInstance.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
      mapInstance.addControl(new mapboxgl.NavigationControl());

      mapInstance.on('load', () => {
        setMap(mapInstance);
        PinPlottingUtil.current = new PinPlottingUtilClass(mapInstance, t);
        mapInstance.resize();
      });
    };

    if (!map) {
      initializeMap();
    }
  }, [map, props.previousViewState, t, defaultView]);

  useEffect(() => {
    if (!map) return;

    const removeLayers = () => {
      layers.current.forEach((layer) => map.removeLayer(layer));
      layers.current = [];
    };

    const removeSources = () => {
      sources.current.forEach((source) => map.removeSource(source));
      sources.current = [];
    };

    const removeMarkers = () => {
      PinPlottingUtil.current.removeAllMarkers();
      markers.current.forEach((marker) => marker.remove());
      markers.current = [];
    };

    /**
     * Removes all information from the map
     * @function
     */
    const clearData = () => {
      removeLayers();
      removeSources();
      removeMarkers();
    };

    clearData();
  }, [map, props.shipmentsData, props.regionsData, props.routeData]);

  useEffect(() => {
    const plotData = () => {
      if (props.regionsData) {
        props.regionsData.forEach((region) => {
          const mapData = PlanningMapUtil.plotRegion(map, region);
          if (mapData) {
            sources.current = [...sources.current, mapData.source];
            layers.current = [...layers.current, mapData.layer];
          }
        });
      }

      if (props.routeData) {
        const allMarkers = [...markers.current];
        props.routeData.forEach((route) => {
          const routeLayer = PlanningMapUtil.plotRoute(map, route.route);
          layers.current = [...layers.current, routeLayer];

          const startEndMarkers = PlanningMapUtil.plotRouteStartAndEndPoint(map, route.startPoint, route.endPoint);
          allMarkers.push(...startEndMarkers);
        });
        markers.current = allMarkers;
      }

      if (props.shipmentsData && props.routeData) {
        plotPoints();
      }
    };

    if (!map) return;

    plotData();
  }, [map, props.shipmentsData, props.regionsData, props.routeData, plotPoints]);

  const setMapRef = (el) => {
    mapContainer.current = el;
  };

  return (
    <div className="plan-preview">
      <MapBoxWrapper setMapRef={setMapRef} />
      {showFooter() && <PlanningFooter data={{ distance: props.routeData[0].distance, duration: props.routeData[0].duration }} />}
    </div>
  );
}

PlanPreview.propTypes = {
  regionsData: PropTypes.arrayOf(
    PropTypes.shape({
      regionId: PropTypes.string,
      colorId: PropTypes.number,
      hexIds: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  routeData: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      startPoint: PropTypes.arrayOf(PropTypes.number),
      midPoint: PropTypes.arrayOf(PropTypes.number),
      endPoint: PropTypes.arrayOf(PropTypes.number),
      distance: PropTypes.number,
      duration: PropTypes.number
    })
  ),
  // TODO this is outdated fix me
  shipmentsData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        Address: PropTypes.string,
        Content: PropTypes.string,
        Country: PropTypes.string,
        CreationTime: PropTypes.string,
        DeliveryTime: PropTypes.string,
        Email: PropTypes.string,
        HouseNumber: PropTypes.string,
        lat: PropTypes.string,
        lng: PropTypes.string,
        Name: PropTypes.string,
        Phone: PropTypes.string,
        PostalCode: PropTypes.string,
        SenderName: PropTypes.string,
        ShipmentCode: PropTypes.string,
        ShipmentID: PropTypes.string,
        ShipmentValue: PropTypes.string,
        ShippingMethod: PropTypes.string,
        StreetName: PropTypes.string
      })
    )
  ),
  previousViewState: PropTypes.shape({
    altitude: PropTypes.number,
    bearing: PropTypes.number,
    height: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    maxPitch: PropTypes.number,
    maxZoom: PropTypes.number,
    minPitch: PropTypes.number,
    minZoom: PropTypes.number,
    pitch: PropTypes.number,
    transitionDuration: PropTypes.number,
    width: PropTypes.number,
    zoom: PropTypes.number
  })
};

PlanPreview.defaultProps = {
  regionsData: null,
  routeData: null,
  shipmentsData: null,
  previousViewState: null
};
