export const MARKER_STATES = {
  STANDARD: 'standard',
  DISABLED: 'disabled',
  IMPACTFUL: 'impactful'
};

export const MARKER_ICON_NAMES_MAPPING = {
  packageLockers: 'icon-parcel-locker-new',
  parcelShops: 'icon-parcel-shops-new',
  sbbShops: 'icon-gls',
  demoShops: 'icon-DPD',
  gasStations: 'icon-gas-station-new',
  unwantedLocationPin: 'icon-close'
};

export const MARKER_ICON_NAMES_IMPACT_POINTS = ['icon-number-one', 'icon-number-two', 'icon-number-three'];
