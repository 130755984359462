import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import './ImpactfulLocationsList.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as CoordinatesActions from '../../../../../state/actions/coordinatesActions';
import MixPanel from '../../../../../setup/mixPanel';
import StringUtils from '../../../../../common/utils/stringUtil';

export default function ImpactfulLocationsList(props) {
  const { t } = useTranslation();
  const [isAdditionalDataToggled, setSelectedIndex] = useState(false);
  const dispatch = useDispatch();

  function toggleAdditionalData() {
    MixPanel.track(
      `Delivery Area Analysis - ${StringUtils.firstLetterToUppercase(props.impactType)} impactful locations list ${
        isAdditionalDataToggled ? 'collapse' : 'expand'
      } clicked`
    );
    setSelectedIndex(!isAdditionalDataToggled);
  }

  const onItemClicked = useCallback(
    (mapPoint) => {
      MixPanel.track(`Delivery Area Analysis - ${StringUtils.firstLetterToUppercase(props.impactType)} impactful locations list item clicked`);
      dispatch(CoordinatesActions.coordinatesChange({ lat: mapPoint.lat, lng: mapPoint.lng }));
    },
    [props.impactType, dispatch]
  );

  const pointImpactRows = useMemo(() => {
    const { mapPointsStaticData, pointImpactList, impactType } = props;

    return pointImpactList[impactType].flatMap((point, index) => Object.entries(point)
      .filter(([mapPointId]) => mapPointId && mapPointsStaticData[mapPointId])
      .map(([mapPointId, mapPointCoverage]) => (
        <div className="location-row" key={mapPointId} onClick={() => onItemClicked(mapPointsStaticData[mapPointId])}>
          <div className="ordinal-number">{`${index + 1}.`}</div>
          <div className="location-name">{mapPointsStaticData[mapPointId].name}</div>
          <div className="location-coverage-count">{mapPointCoverage}</div>
        </div>
      )));
  }, [props, onItemClicked]);

  return (
    <div className="impactful-locations-table">
      <div className="impactful-locations-title">
        <div className={`circle ${props.impactType}`} />
        {t(`${props.impactType} impactful locations`)}
        <div className="arrow-wrapper" onClick={toggleAdditionalData}>
          {isAdditionalDataToggled ? <i className="icon icon-keyboard-arrow-up" /> : <i className="icon icon-keyboard-arrow-down" />}
        </div>
      </div>
      <div className={`locations-list ${isAdditionalDataToggled ? 'expanded' : ''}`}>
        <div className="animation-container">{pointImpactRows}</div>
      </div>
    </div>
  );
}

ImpactfulLocationsList.propTypes = {
  impactType: PropTypes.string.isRequired,
  mapPointsStaticData: PropTypes.object.isRequired,
  pointImpactList: PropTypes.object.isRequired
};
