import React from 'react';
import './InfoMessage.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COVERAGE, LOCATIONS } from '../constants/formTypes';

export const INFO_MESSAGE_TYPE = {
  RECOMMENDER_RESULT: 'RECOMMENDER_RESULT',
  DEFAULT_INFO: 'DEFAULT_INFO',
};

export default function InfoMessage(props) {
  const { t } = useTranslation();
  function getMessageUiData() {
    if (props.messageType === INFO_MESSAGE_TYPE.DEFAULT_INFO) {
      return {
        icon: (<i className="icon icon-info" />),
        className: 'info',
        messageDiv: getInfoMessageElement()
      };
    }

    if (props.messageType === INFO_MESSAGE_TYPE.RECOMMENDER_RESULT) {
      const recommenderResultsAdditionalData = props.additionalMessageData;

      // In case of state machine error show error message
      if (recommenderResultsAdditionalData.recommendationResults?.error) {
        return {
          icon: (<i className="icon icon-warning-circle" />),
          className: 'error'
        };
      }

      if (recommenderResultsAdditionalData.recommendationType === LOCATIONS) {
        // in case we manage to put enough locations we show success message
        if (recommenderResultsAdditionalData.targetLocations <= recommenderResultsAdditionalData.recommendationResults.numberOfSelectedLocations) {
          return {
            icon: (<i className="icon icon-check-circle" />),
            className: 'successful',
            messageDiv: getResultMessageElement()
          };
        }

        // in case we don't manage to put enough locations we show warning message
        return {
          icon: (<i className="icon icon-warning-triangle" />),
          className: 'warning',
          messageDiv: getResultMessageElement()
        };
      }

      if (recommenderResultsAdditionalData.recommendationType === COVERAGE) {
        // in case we manage to reach target coverage we show success message
        if (recommenderResultsAdditionalData.targetCoverage <= recommenderResultsAdditionalData.recommendationResults.baseLayerCoveragePercent) {
          return {
            icon: (<i className="icon icon-check-circle" />),
            className: 'successful',
            messageDiv: getResultMessageElement()
          };
        }

        // in case we don't manage to reach target coverage we show warning message
        return {
          icon: (<i className="icon icon-warning-triangle" />),
          className: 'warning',
          messageDiv: getUnsuccessfulResultMessageElement()
        };
      }
    }

    return null;
  }

  function getCoverageMessagePart(message) {
    const resultCoverage = Math.trunc(props.additionalMessageData.recommendationResults.baseLayerCoveragePercent);
    return (
      <div>
        <span>{t(message, { coverage: Math.trunc(props.additionalMessageData.recommendationResults.baseLayerCoveragePercent) })}</span>
        <span>
          :&nbsp;
          {resultCoverage}
          %
        </span>
      </div>
    );
  }

  function getResultMessageElement() {
    return (
      <div>
        {getCoverageMessagePart('successful-coverage-message-beginning')}
        <div>
          {t('location-message', { locations: props.additionalMessageData.recommendationResults.numberOfSelectedLocations })}
        </div>
      </div>
    );
  }

  function getUnsuccessfulResultMessageElement() {
    return (
      <div>
        {getCoverageMessagePart('unsuccessful-coverage-message-beginning')}
        <div>
          {t('location-message', { locations: props.additionalMessageData.recommendationResults.numberOfSelectedLocations })}
        </div>
      </div>
    );
  }

  function getInfoMessageElement() {
    return (
      <div>
        <div>
          {props.additionalMessageData.infoMessage}
        </div>
      </div>
    );
  }

  const messageUiData = getMessageUiData();

  return (
    <div className={`info-message-wrapper ${messageUiData?.className} ${props.messageType ? 'visible' : ''}`}>
      {messageUiData?.icon}
      <div className="message">
        {messageUiData?.messageDiv}
      </div>
    </div>
  );
}

InfoMessage.propTypes = {
  messageType: PropTypes.string,
  additionalMessageData: PropTypes.oneOfType([
    PropTypes.shape({
      recommendationType: PropTypes.string.isRequired,
      targetCoverage: PropTypes.number.isRequired,
      targetLocations: PropTypes.number.isRequired,
      recommendationResults: PropTypes.shape({
        numberOfSelectedLocations: PropTypes.number,
        coveragePercent: PropTypes.number,
        baseLayerCoveragePercent: PropTypes.number,
        error: PropTypes.string
      }).isRequired
    }),
    PropTypes.shape({ infoMessage: PropTypes.string })
  ])
};

InfoMessage.defaultProps = {
  messageType: null,
  additionalMessageData: null
};
