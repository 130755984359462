import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from '../../../../../common/components/form/Form';
import { FormInput } from '../../../../../common/components/form/components/FormInput';
import Button from '../../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import '../../../../../common/components/form/utils/defaultFormDialogCss.scss';
import './RecommenderDialogInputForm.scss';
import { COVERAGE } from '../../constants/formTypes';
import MixPanel from '../../../../../setup/mixPanel';
import { BUTTON_TYPES } from '../../../../../common/components/buttons/button/constants/buttonTypes';
import DividerLine from '../../../../../common/components/dividerLine/DividerLine';
import { RECOMMENDER_ENRICHMENT_LAYERS } from '../../constants/userMapLayers';
import AuthUtil from '../../../../../common/utils/authUtil';

class MultiLayerInputForm extends React.Component {
  constructor(props) {
    super(props);

    this.title = this.props.t('Generate new locations');
    this.description = this.props.t('Choose layers to enrich the network generator');

    const userHexTypes = AuthUtil.getRegionAnalysisLayers();
    this.availableRecommenderLayers = RECOMMENDER_ENRICHMENT_LAYERS.filter((element) => userHexTypes.includes(element));
    this.state = { formData: { ...this.getDefaultValuesForRecommenderLayers(this.availableRecommenderLayers) } };
    this.formRef = null;
  }

  getDefaultValuesForRecommenderLayers = (availableRecommenderLayers) => {
    const defaultValues = {};
    availableRecommenderLayers.forEach((layer) => {
      defaultValues[layer] = false;
    });

    return defaultValues;
  };

  handleSubmit = () => {
    if (!this.formRef) {
      return;
    }

    if (this.props.submitCallback) {
      // Filter layers based on formData keys
      const filteredLayers = this.availableRecommenderLayers
        .filter((layer) => this.state.formData[layer])
        .map((layer) => ({ layerType: layer }));

      // Set weight for each layer. Formula = 1 / (length of selected layers + 1 base layer)
      const weight = 1 / (filteredLayers.length + 1);
      const allLayers = filteredLayers.map((layer) => ({ ...layer, weight }));

      // Add the base layer
      allLayers.unshift({ layerType: this.props.currentLayer, weight });
      this.props.submitCallback({
        coverage: this.props.coverage,
        locations: this.props.locations,
        placePins: this.props.placePins,
        coverageType: this.props.coverageType,
        layers: allLayers
      });
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  cancelCallback = () => {
    MixPanel.track('Delivery Area Analysis - Recommendation form canceled', { formType: this.props.formType });

    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  render() {
    return (
      <div className="coverage-input-form">
        <div className="title">{this.title}</div>
        <div className="close-button" onClick={this.cancelCallback}>
          <i className="icon icon-close" />
        </div>
        <DividerLine />
        <Form
          ref={(formRef) => {
            this.formRef = formRef;
          }}
          formData={this.state.formData}
          onFormChange={(newFormData) => {
            this.setState({ formData: newFormData });
          }}
          className="multi-layer-form"
        >
          <div className="description multi-layer-title">{this.description}</div>
          <div className="optional">
            (
            {this.props.t('optional')}
            )
          </div>
          {this.availableRecommenderLayers.map((field) => (
            this.props.currentLayer !== field && (
            <FormInput
              key={field}
              inputType="checkbox"
              label={this.props.t(field)}
              name={field}
            />
            )
          ))}
        </Form>

        <DividerLine />
        <div className="buttons">
          <div className="step-label">{this.props.t('Step 2/2')}</div>
          <Button onClick={this.cancelCallback} type={BUTTON_TYPES.SECONDARY} text={this.props.t('Cancel')} />
          <Button onClick={this.handleSubmit} type={BUTTON_TYPES.PRIMARY} text={this.props.t('Generate')} />
        </div>
      </div>
    );
  }
}

MultiLayerInputForm.propTypes = {
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  formType: PropTypes.string,
  currentLayer: PropTypes.string,
  locations: PropTypes.string,
  coverage: PropTypes.string,
  placePins: PropTypes.bool,
  coverageType: PropTypes.string.isRequired
};

MultiLayerInputForm.defaultProps = {
  submitCallback: null,
  cancelCallback: null,
  formType: COVERAGE,
  currentLayer: '',
  locations: 0,
  coverage: 100,
  placePins: false
};

export default withTranslation('translations')(MultiLayerInputForm);
