import { useEffect, useRef } from 'react';

/*
   * This hook allows you to set a timeout for a specified duration
   * and runs an effect when the timeout is done.
   * It also provides the information if the timer countdown is in progress or not.
   * @param {Function} callback - the effect to run when timeout finished.
   * @param {Function} duration - the duration of the timeout.
   * @returns {Object} - an object with the following properties:
   * startTimeout - method that starts the timeout
 */
export function useTimeout(callback, duration) {
  const savedCallback = useRef(callback);

  const startTimeout = useRef(null);
  const timeoutId = useRef(null);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no duration is specified.
    // Note: 0 is a valid value for duration.
    if (!duration && duration !== 0) {
      return undefined;
    }

    startTimeout.current = () => {
      timeoutId.current = setTimeout(() => {
        savedCallback.current();
      }, duration * 1000);
    };

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [duration]);

  return { startTimeout: startTimeout.current };
}
