import { Auth } from 'aws-amplify';
import { gql } from '@apollo/client';
import ApiHelpers from '../../common/utils/api/apiHelpers';
import dynamoStringExtensionsUtil from '../../common/utils/api/dynamoStringExtensionsUtil';
import SortUtil from '../../common/utils/sortUtil';
import { raygunClient } from '../../setup/raygunClient';
import * as AuthActions from '../../state/actions/authActions';
import store from '../../state/store';
import { appSyncClient } from '../../setup/appSyncClient';

const GetUserPermissions = gql`
  query GetUserPermissions1($pk: String, $sk: String) {
    getAllRoles(pk: $pk, sk: $sk) {
      id
      name
      zoneId
      policyId
      zone {
        id
        name
        teams {
          id
          name
          teamType
          description
          integrationId
        }
        deliveryAreas {
          id
          name
          lat
          lng
          zoom
          hexagonsFile
          regionsFile
          polygonFile
          integrationId
        }
        hubs {
          id
          name
          lat
          lng
          integrationId
        }
      }

      policy {
        id
        name
        permissions {
          management {
            courierManagement
            hubManagement
            deliveryAreaManagement
            userManagement
            shipmentManagement
            labeling
            zipCodeManagement
          }

          planning {
            planning
            planTracking
          }
          execution {
            realTime
          }

          analytics {
            courierAnalysis
            deliveryAreaAnalysis
            routeAnalysis
            statistics
            oohAnalysis
          }
        }
      }
    }
  }
`;

const UpdateUserIntercomPermissions = gql`
  mutation updateUserIntercomPermissions(
    $email: String
    $permissions: PermissionsInputData
  ) {
    updateUserIntercomPermissions(
      email: $email
      permissions: $permissions
    )
  }
`;

async function loadUserPermissions() {
  const currentUser = await Auth.currentUserInfo();
  raygunClient.setUser(
    { identifier: currentUser.id, email: currentUser.attributes.email },
    { userGroup: currentUser.attributes['custom:user_group_id'], roleId: currentUser.attributes['custom:roleId'] }
  );
  const userRoleId = currentUser.attributes && currentUser.attributes['custom:roleId'];
  const userTenant = currentUser.attributes && currentUser.attributes['custom:tenantId'];
  const username = currentUser.username;
  if (userRoleId) {
    await setUserPermissions(userRoleId, userTenant, username);
  }
}

function prepareIntercomPermissionsObject(permissionsData) {
  const permissions = ApiHelpers.removeTypenameFromObject(permissionsData);
  const permissionObject = {};
  Object.keys(permissions).forEach((pageGroup) => {
    if (permissions[pageGroup]) {
      permissionObject[pageGroup] = {};
      Object.keys(permissions[pageGroup]).forEach((page) => {
        if (permissions[pageGroup][page]) {
          permissionObject[pageGroup][page] = [page];
          if (permissions[pageGroup][page].length > 0) {
            permissions[pageGroup][page].forEach((feature1) => {
              permissionObject[pageGroup][page].push(feature1);
            });
          }
          permissionObject[pageGroup][page] = permissionObject[pageGroup][page].join(';');
        }
      });
    }
  });
  return permissionObject;
}

async function updateUserIntercomPermissions(email, permissionsData) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: UpdateUserIntercomPermissions,
      variables: {
        email: email,
        permissions: prepareIntercomPermissionsObject(permissionsData)
      }
    });
  }

  return null;
}

function getPermissionsFromApiResponse(role, userTenant, username) {
  return {
    teams: SortUtil.sortObjectByUpperCaseField(convertArrayToPermissionDataObject(role?.zone?.teams || []), 'name'),
    groupedFeaturePermissions: role?.policy?.permissions,
    features: getFeatures(role?.policy?.permissions),
    deliveryAreas: convertArrayToPermissionDataObject(role?.zone?.deliveryAreas || []),
    hubs: convertArrayToPermissionDataObject(role?.zone?.hubs || []),
    additionalMapFeatures: getAdditionalMapFeaturesFromPolicy(role?.policy?.permissions?.analytics?.deliveryAreaAnalysis || []),
    userTenant,
    username,
    zoneName: role.zone?.name,
    policyName: role.policy?.name,
    roleId: role.id
  };
}

async function setUserPermissions(userRoleId, userTenant, username) {
  let permissions;
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const roleData = await ApiHelpers.createQuery(GetUserPermissions, { pk: 'ROLE', sk: dynamoStringExtensionsUtil.addRolePrefix(userRoleId) });
    permissions = getPermissionsFromApiResponse(roleData.data.getAllRoles[0], userTenant, username);
  } else {
    const userData = await fetch('/testData/management/permissions.json').then((data) => data.json());

    permissions = getPermissionsFromApiResponse(userData.data.getAllRoles.find((role) => role.id === userRoleId), userTenant, 'test');
  }

  updateUserIntercomPermissions(username, permissions.groupedFeaturePermissions);
  store.dispatch(AuthActions.setUserPermissions(permissions));
}

function getAdditionalMapFeaturesFromPolicy(deliveryAreaFeatures) {
  return deliveryAreaFeatures
    .map((feature) => {
      if (feature.startsWith('mf-')) {
        return feature.slice(3);
      }

      return null;
    })
    .filter(Boolean);
}

function convertArrayToPermissionDataObject(array) {
  const response = {};
  array.forEach((item) => {
    response[item.id] = item;
  });

  return response;
}

function getFeatures(permissionsData) {
  const permissions = ApiHelpers.removeTypenameFromObject(permissionsData);
  const features = [];
  Object.keys(permissions).forEach((pageGroup) => {
    if (permissions[pageGroup]) {
      Object.keys(permissions[pageGroup]).forEach((page) => {
        if (permissions[pageGroup][page]) {
          features.push(page);
          if (permissions[pageGroup][page].length > 0) {
            permissions[pageGroup][page].forEach((feature1) => {
              features.push(feature1);
            });
          }
        }
      });
    }
  });

  return features;
}

export const SetupApi = { loadUserPermissions: loadUserPermissions };
