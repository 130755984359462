import moment from 'moment';
import * as _ from 'lodash';

/**
 * Sorts keys of a given object.
 *
 * @param {object} obj - object to be sorted
 * @returns {object} object with sorted keys
 * @memberOf SortUtil
 * @function
 */
function sortObject(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      // eslint-disable-next-line no-param-reassign
      result[key] = obj[key];
      return result;
    }, {});
}

function sortObjectByUpperCaseField(data, fieldName) {
  if (!data) {
    return {};
  }

  const entries = Object.entries(data).sort((a, b) => {
    const nameA = a[1][fieldName].toUpperCase();
    const nameB = b[1][fieldName].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  return Object.fromEntries(entries);
}

function sortArrayByUpperCaseField(data, fieldName) {
  if (!data || data.length <= 0) {
    return [];
  }

  return data.sort((a, b) => {
    const nameA = a[fieldName].toUpperCase();
    const nameB = b[fieldName].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
}

function sortAddresses(addresses) {
  return addresses.sort((firstAddress, secondAddress) => {
    if (firstAddress.streetName === secondAddress.streetName) {
      const firstHouseNumber = parseInt(firstAddress.houseNumber, 10);
      const secondHouseNumber = parseInt(secondAddress.houseNumber, 10);
      if (!firstHouseNumber) return -1;
      if (!secondHouseNumber) return 1;
      if (firstHouseNumber === secondHouseNumber) return firstAddress.houseNumber > secondAddress.houseNumber ? 1 : -1;
      return firstHouseNumber > secondHouseNumber ? 1 : -1;
    }
    return firstAddress.streetName > secondAddress.streetName ? 1 : -1;
  });
}

function sortRouteLocations(route) {
  let filteredRoute = route || [];
  filteredRoute = filteredRoute.sort((a, b) => {
    const am = moment(a.gpsTimestamp);
    const bm = moment(b.gpsTimestamp);
    if (am.isBefore(bm)) {
      return -1;
    }
    if (am.isSame(bm)) {
      return 0;
    }

    return 1;
  });
  return _.uniqBy(filteredRoute, 'gpsTimestamp');
}

const sortByPredefinedOrder = (arr, order) => {
  return arr.sort((a, b) => {
    const indexA = order.indexOf(a);
    const indexB = order.indexOf(b);

    // If both elements are in the predefined order, compare their indices
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only one element is in the predefined order, prioritize it
    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }

    // If neither element is in the predefined order, maintain their original order
    return arr.indexOf(a) - arr.indexOf(b);
  });
};

const sortByNumericField = (arr, field) => {
  return arr.sort((a, b) => {
    if (a[field] > b[field]) return -1;
    if (a[field] < b[field]) return 1;
    return 0;
  });
};

/**
 * Util for sorting objects and arrays
 *
 * @namespace
 * @category Common
 */
const SortUtil = {
  sortObject: sortObject,
  sortArrayByUpperCaseField: sortArrayByUpperCaseField,
  sortObjectByUpperCaseField: sortObjectByUpperCaseField,
  sortAddresses: sortAddresses,
  sortRouteLocations: sortRouteLocations,
  sortByPredefinedOrder: sortByPredefinedOrder,
  sortByNumericField: sortByNumericField
};

export default SortUtil;
