import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import './SelectedLocationsMenu.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import SimpleBar from 'simplebar-react';
import useSelectedLocationsCoverages from '../../hooks/useSelectedLocationsCoverages';
import MapPointCountIcon from './MapPointCountIcon';
import DistanceTypeUtil, { DISTANCE_VALUE_TYPE_KEYS_ARRAY } from '../../../../../common/utils/distanceTypeUtil';
import IsochronePolygonSymbol from '../../../../../common/components/popups/components/IsochronePolygonSymbol';
import MixPanel from '../../../../../setup/mixPanel';
import * as CoordinatesActions from '../../../../../state/actions/coordinatesActions';
import 'simplebar-react/dist/simplebar.min.css';

export default function SelectedLocationsMenu(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { coveragePercentage, coverageData, mapPointCountIconData, enrichedMapPointDataArray } = useSelectedLocationsCoverages(
    props.hexType,
    props.distanceType,
    props.allPointsStaticData,
    props.allPointsCoverageData,
    props.allPointsIsochroneData,
    props.distancePinsStaticData,
    props.distancePinsIsochroneData,
    props.distancePinsCoverageData,
    props.hexLayersCountMap,
    props.deliveryAreaTotalCount
  );

  const getUnit = useMemo(() => DistanceTypeUtil.getUnit(props.distanceType), [props.distanceType]);

  const getTotalCoverageRows = () => {
    return DISTANCE_VALUE_TYPE_KEYS_ARRAY.map((distanceLevel) => {
      const value = DistanceTypeUtil.getDistanceValue(props.distanceType, distanceLevel);
      return (
        <div key={distanceLevel} className="menu-totals-counts-row">
          <div className="distance-level">
            <IsochronePolygonSymbol polygonDistance={distanceLevel} />
            {value}
            {getUnit}
          </div>
          <div className="coverage-value">
            {!_.isEmpty(coverageData) && coverageData[props.hexType][distanceLevel]}
          </div>
        </div>
      );
    });
  };

  const getMapPointIcon = (mapPointType) => {
    return <img alt={`/mapData/${mapPointType}_logo.svg`} className="background-image" src={`/mapData/${mapPointType}_logo.svg`} key={mapPointType} />;
  };

  const itemClicked = (mapPoint) => {
    MixPanel.track(`Delivery Area Analysis - Selected locations menu ${mapPoint.type} item clicked`);
    dispatch(CoordinatesActions.coordinatesChange({ lat: mapPoint.lat, lng: mapPoint.lng }));
  };

  const getSelectedMapPointRowsComponent = () => {
    return enrichedMapPointDataArray?.map((mapPoint, i) => (
      <div key={mapPoint.id} className="selected-point" onClick={() => itemClicked(mapPoint)}>
        <div className="map-point-details">
          <div className="map-point-icon">
            {getMapPointIcon(mapPoint.iconType || mapPoint.type)}
          </div>
          <div className="map-point-name">
            {`${i + 1}. ${mapPoint.name}`}
          </div>
        </div>
        <div className="map-point-coverage">
          {mapPoint.coverage}
        </div>
      </div>
    ));
  };

  const getRootClassName = () => {
    const componentVisibleFlag = enrichedMapPointDataArray?.length > 0;
    return `selected-locations-menu-container${componentVisibleFlag ? ' visible' : ''}`;
  };

  return (
    <div className={getRootClassName()}>
      <div className="selected-locations-menu">
        <div className="menu-title">
          {t('Selected locations')}
        </div>
        <div className="menu-subtitle">
          {t('Coverage')}
        </div>
        <div className="menu-totals-percentage">
          <div className="map-point-icon-counts">
            {
              mapPointCountIconData.map((iconData) => <MapPointCountIcon key={iconData.type} count={iconData.count} imageName={iconData.type} />)
            }
          </div>
          <div className="percentage">
            {
              `${coveragePercentage && coveragePercentage[props.hexType]}`
            }
          </div>
        </div>
        <div className="menu-totals-counts">
          {getTotalCoverageRows()}
        </div>
        <div className="selected-points-array">
          <SimpleBar style={{ height: 'auto' }} className="selected-points-scroll">
            {getSelectedMapPointRowsComponent()}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}

SelectedLocationsMenu.propTypes = {
  hexType: PropTypes.string,
  distanceType: PropTypes.string.isRequired,
  allPointsStaticData: PropTypes.object,
  allPointsCoverageData: PropTypes.object,
  allPointsIsochroneData: PropTypes.object,
  distancePinsStaticData: PropTypes.object,
  distancePinsIsochroneData: PropTypes.object,
  distancePinsCoverageData: PropTypes.object,
  hexLayersCountMap: PropTypes.object,
  deliveryAreaTotalCount: PropTypes.object
};

SelectedLocationsMenu.defaultProps = {
  hexType: null,
  hexLayersCountMap: null,
  allPointsStaticData: null,
  allPointsCoverageData: null,
  allPointsIsochroneData: null,
  distancePinsStaticData: null,
  distancePinsIsochroneData: null,
  distancePinsCoverageData: null,
  deliveryAreaTotalCount: null
};
