import React, { useEffect, useState } from 'react';
import './App.scss';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Amplify, Auth } from 'aws-amplify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import CourierAnalysis from '../../features/analysis/courierAnalysis/CourierAnalysis';
import OOHAnalysis from '../../features/analysis/oohAnalysis/OohAnalysis';
import UsersPermissions from '../../features/management/users/UsersPermissions';
import ZipCodeRoutes from '../../features/management/deliveryAreas/ZipCodeRoutes';
import PlanTracking from '../../features/planning/planTracking/PlanTracking';
import RealTime from '../../features/realTime/RealTime';
import AddressLabelingPage from '../../features/management/addressLabeling/AddressLabelingPage';
import { amplifyI18n } from '../../setup/amplifyTranslate';
import { SetupApi } from '../api/setupApi';
import SideMenu from './SideMenu';
import SignInToolbar from './SignInToolbar';
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import PageNotFound from './PageNotFound';
import RouteAnalysis from '../../features/analysis/routeAnalysis/RouteAnalysis';
import MixPanel from '../../setup/mixPanel';
import AuthUtil from '../../common/utils/authUtil';
import StatisticPage from '../../features/analysis/statistics/StatisticPage';
import CouriersPage from '../../features/management/couriers/CouriersPage';
import AppDialog from '../../common/components/dialogs/AppDialog';
import './GlobalCss.scss';
import PlanningPageRoutes from '../../features/planning/planManagement/PlanningPageRoutes';
import DeliveryAreaRoutes from '../../features/management/deliveryAreas/DeliveryAreaRoutes';
import HubRoutes from '../../features/management/hubs/HubRoutes';
import BackendResourceConfigUtil from '../../common/utils/api/backendResourceConfigUtil';
import ShipmentManagement from '../../features/management/shipments/ShipmentManagement';
import StreetMapping from '../../features/analysis/streetMapping/StreetMapping';
import DeliveryAreaAnalysis from '../../features/analysis/deliveryAreaAnalysis/DeliveryAreaAnalysis';
import ErrorBoundary from './ErrorBoundary';
import RouteUtil from '../utils/routeUtil';

Amplify.configure({
  Auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: BackendResourceConfigUtil.getApiName(),
        endpoint: BackendResourceConfigUtil.getApiEndPoint()
      }
    ]
  },
  Storage: {
    bucket: BackendResourceConfigUtil.getRegionDataBucketName(),
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    customPrefix: { public: '' }
  }
});

Auth.configure();

// Supported log levels: ERROR, WARN, INFO, DEBUG, VERBOSE
Amplify.Logger.LOG_LEVEL = 'ERROR';

const signInComponents = {
  Header() {
    return (
      <div className="sign-in-toolbar-wrapper">
        <SignInToolbar />
      </div>
    );
  },

  SignIn: {
    Header() {
      return (
        <div className="login-title">{amplifyI18n.get('Sign in to your account')}</div>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <div className="login-footer">
          <span>{amplifyI18n.get('Forgot your password?')}</span>
          <span className="reset-password" onClick={toResetPassword}>{amplifyI18n.get('Reset Password')}</span>
        </div>
      );
    }
  }
};

/**
 * Defines the whole app structure and layout
 *
 * @returns {JSX.Element} - whole app
 * @component
 * @alias App
 * @category App
 */
function App() {
  const isPageLoading = useSelector((state) => state.pageState.isPageLoading);
  const isMapLoading = useSelector((state) => state.mapState.isMapLoading);
  const dispatch = useDispatch();
  const userPermissions = useSelector((state) => state.authState.userPermissions);
  const { t } = useTranslation();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const { user } = useAuthenticator((context) => [context.user]);
  const [pages, setPages] = useState(null);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      const getUserPermissions = async () => {
        await SetupApi.loadUserPermissions();
      };
      getUserPermissions();
    }
  }, [dispatch, authStatus]);

  useEffect(() => {
    if (authStatus === 'authenticated' && userPermissions) {
      const newPages = [];

      // Management pages block

      if (
        AuthUtil.hasAnyOfFeaturesEnabled([
          'courierManagement',
          'deliveryAreaManagement',
          'hubManagement',
          'userManagement'
        ])
      ) {
        newPages.push({
          key: 'management-separator',
          text: t('Management'),
          type: 'SEPARATOR'
        });
      }

      if (AuthUtil.isFeatureEnabled('courierManagement')) {
        newPages.push({
          key: 'courier-management',
          component: CouriersPage,
          iconName: 'account-group',
          text: t('Couriers and teams'),
          tooltip: t('Couriers and teams tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('deliveryAreaManagement')) {
        newPages.push({
          key: 'delivery-areas',
          component: DeliveryAreaRoutes,
          iconName: 'map',
          text: t('Delivery Areas'),
          tooltip: t('Delivery Areas tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('hubManagement')) {
        newPages.push({
          key: 'hub-management',
          component: HubRoutes,
          iconName: 'location-on',
          text: t('Hubs'),
          tooltip: t('Hub management')
        });
      }

      if (AuthUtil.isFeatureEnabled('userManagement')) {
        newPages.push({
          key: 'user-management',
          component: UsersPermissions,
          iconName: 'account-cog',
          text: t('User management'),
          tooltip: t('User management tooltip')
        });
      }

      // Planning pages block

      if (AuthUtil.hasAnyOfFeaturesEnabled([
        'planning',
        'planTracking'
      ])) {
        newPages.push({
          key: 'planning-separator',
          text: t('Planning'),
          type: 'SEPARATOR'
        });
      }

      if (AuthUtil.isFeatureEnabled('planning')) {
        newPages.push({
          key: 'delivery-plan',
          component: PlanningPageRoutes,
          iconName: 'list-alt',
          text: t('Delivery plans'),
          tooltip: t('Delivery plans tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('planTracking')) {
        newPages.push({
          key: 'delivery-plan-tracking',
          component: PlanTracking,
          iconName: 'content-search',
          text: t('Delivery plan tracking'),
          tooltip: t('Delivery plan tracking tooltip')
        });
      }

      // Execution pages block

      if (AuthUtil.isFeatureEnabled('realTime')) {
        newPages.push({
          key: 'realTime-separator',
          text: t('Execution'),
          type: 'SEPARATOR'
        });
      }

      if (AuthUtil.isFeatureEnabled('realTime')) {
        newPages.push({
          key: 'real-time',
          component: RealTime,
          iconName: 'history',
          text: t('Real-time tracking'),
          tooltip: t('Real-time tracking')
        });
      }

      // Analysis pages block

      if (AuthUtil.hasAnyOfFeaturesEnabled(['oohAnalysis', 'courierAnalysis', 'routeAnalysis', 'statistics', 'deliveryAreaAnalysis'])) {
        newPages.push({
          key: 'analysis-separator',
          text: t('Analytics'),
          type: 'SEPARATOR'
        });
      }

      if (AuthUtil.isFeatureEnabled('oohAnalysis')) {
        newPages.push({
          key: 'ooh-analysis',
          component: OOHAnalysis,
          iconName: 'parcel-locker',
          text: t('OOH point analysis'),
          tooltip: t('OOH point analysis')
        });
      }

      if (AuthUtil.isFeatureEnabled('courierAnalysis')) {
        newPages.push({
          key: 'courier-analysis',
          component: CourierAnalysis,
          iconName: 'local-shipping',
          text: t('Courier analysis'),
          tooltip: t('Courier analysis')
        });
      }

      if (AuthUtil.isFeatureEnabled('routeAnalysis')) {
        newPages.push({
          key: 'route-analysis',
          component: RouteAnalysis,
          iconName: 'timeline',
          text: t('Route analysis'),
          tooltip: t('Route analysis')
        });
      }

      if (AuthUtil.isFeatureEnabled('statistics')) {
        newPages.push({
          key: 'statistics',
          component: StatisticPage,
          iconName: 'bar-chart',
          text: t('Statistics'),
          tooltip: t('Statistics')
        });
      }

      if (AuthUtil.isFeatureEnabled('deliveryAreaAnalysis')) {
        newPages.push({
          key: 'delivery-area-analysis',
          component: DeliveryAreaAnalysis,
          iconName: 'map-search',
          text: t('Delivery area analysis'),
          tooltip: t('Delivery area analysis')
        });
      }

      // Add-on pages block

      if (
        AuthUtil.hasAnyOfFeaturesEnabled([
          'streetMapping',
          'labeling',
          'zipCodeManagement',
          'shipmentManagement'
        ])
      ) {
        newPages.push({
          key: 'add-ons-separator',
          text: t('Add-ons'),
          type: 'SEPARATOR'
        });
      }

      if (AuthUtil.isFeatureEnabled('streetMapping')) {
        newPages.push({
          key: 'street-management',
          component: StreetMapping,
          iconName: 'hexagon-multiple',
          text: t('Street mapping'),
          tooltip: t('Street mapping tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('labeling')) {
        newPages.push({
          key: 'address-label',
          component: AddressLabelingPage,
          iconName: 'wrong-location-outlined',
          text: t('Address labeling'),
          tooltip: t('Address labeling')
        });
      }

      if (AuthUtil.isFeatureEnabled('zipCodeManagement')) {
        newPages.push({
          key: 'zip-code',
          component: ZipCodeRoutes,
          iconName: 'markunread-mailbox',
          text: t('Zip Codes'),
          tooltip: t('Zip Codes tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('shipmentManagement')) {
        newPages.push({
          key: 'shipment-management',
          component: ShipmentManagement,
          iconName: 'assignment-turned-in-outlined',
          text: t('Shipments'),
          tooltip: t('Shipment management tooltip')
        });
      }

      setPages(newPages);
    }
  }, [t, userPermissions, authStatus]);

  useEffect(() => {
    window.Intercom('shutdown');
  }, [userPermissions]);

  toast.configure({
    autoClose: 2000,
    position: toast.POSITION.BOTTOM_RIGHT
  });

  const isAuthenticated = authStatus === 'authenticated';

  if (isAuthenticated) {
    MixPanel.identify(user.username);
    if (userPermissions) {
      MixPanel.people()
        .set({
          $email: user.username,
          Email: user.username,
          'User Name': user.username,
          'Policy Name': userPermissions.policyName,
          'Zone Name': userPermissions.zoneName
        });
    }
  }

  const formFields = { resetPassword: { username: { label: 'Email*' } } };

  return (
    <Authenticator hideSignUp components={signInComponents} formFields={formFields}>
      {() => (
        <div className="app">
          {pages && pages.length > 0 && (
            <div className="app">
              <SideMenu pages={pages} />
              <ErrorBoundary>
                <div className="main">
                  <div className="content">
                    <Switch>
                      {pages
                        .filter((p) => !p.type)
                        .map((page) => (
                          <Route path={`/${page.key}`} key={page.key}>
                            {React.createElement(page.component)}
                          </Route>
                        ))}
                      {isAuthenticated && <Redirect exact strict from="/" to={RouteUtil.getDefaultPage()} />}
                      <Route path="*">
                        <PageNotFound />
                      </Route>
                    </Switch>
                  </div>
                </div>

                <AppDialog />

                <Backdrop open={isPageLoading || isMapLoading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </ErrorBoundary>
            </div>
          )}
        </div>
      )}
    </Authenticator>
  );
}

export default App;
